<template>
  <div class="admin-status-codes v-step-status-code-section">
    <div class="title">
      <h1>{{ $t('admin.status-codes.title') }}</h1>
    </div>
    <div class="content">
      <div class="flexbox">
        <div class="boxes">
          <ul class="col-pc-12 col-mobile-12 ul-boxes">
            <li
              v-for="status in statuses.filter(status => status.category !== 'PANIC')"
              :key="status.id"
              class="status-code-box col-mobile-12 col-pc-12"
            >
              <div class="li-content col-all-12">
                <div class="name col-pc-9">
                  <div class="text col-pc-12">
                    <h2>{{ status.display }}</h2>
                  </div>
                </div>
              </div>
              <div class="status-code-settings-dropdown col-f">
                <div class="row col-pc-3 col-mobile-10">
                  <label for="status-code-text">{{ $t('admin.status-codes.button-text') }}</label>
                  <CustomInput
                    style="height: 35px;"
                    class="custom-input primary col-pc-12 col-mobile-12"
                    inputClasses="box custom-height primary col-pc-12 col-mobile-12"
                    type="text"
                    name="status-code-text"
                    :placeholder="$t('admin.status-codes.button-text-input_placeholder')"
                    v-model="status.display"
                    @combinedInput="updateStatus(status, $event)"
                  ></CustomInput>
                </div>
                <div class="row col-pc-6 col-mobile-10">
                  <label for="status-code-type">{{ $t('admin.status-codes.type-of-status') }}</label>
                  <v-select
                    v-model="status.category"
                    :options="['AVAILABLE', 'UNAVAILABLE', 'AWAY']"
                    :clearable="false"
                    class="custom-select col-pc-12 col-mobile-12"
                    @input="updateStatus(status)"
                  ></v-select>
                </div>
                <div class="row col-pc-3 active-switch">
                  <p>{{ $t('admin.status-codes.active') }}</p>
                  <toggle-button
                    id="admin-users-dispatch"
                    v-model="status.isActive"
                    :labels="{
                      checked: $t('panic-buttons-dispatch_component.on'),
                      unchecked: $t('panic-buttons-dispatch_component.off')
                    }"
                    @change="updateStatus(status)"
                  ></toggle-button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="ul-boxes panic">
            <li
              v-for="status in statuses.filter(status => status.category === 'PANIC')"
              :key="status.id"
              class="panic-status-code-box col-mobile-12 col-pc-12"
            >
              <div class="li-content col-all-12">
                <div class="name col-pc-9">
                  <div class="text col-pc-12">
                    <h2>{{ $t('admin.status-codes.panic-title') }}</h2>
                  </div>
                </div>
              </div>
              <div class="panic-status-code-settings-dropdown col-f">
                <div class="row col-pc-6 col-mobile-10">
                  <label for="panic-status-code-text">{{ $t('admin.status-codes.panic-button-text') }}</label>
                  <CustomInput
                    style="height: 35px;"
                    class="custom-input primary col-pc-12 col-mobile-12"
                    inputClasses="box custom-height primary col-pc-12 col-mobile-12"
                    type="text"
                    name="panic-status-code-text"
                    :placeholder="$t('admin.status-codes.button-text-input_placeholder')"
                    v-model="status.display"
                    @input="updateStatus(status)"
                  ></CustomInput>
                </div>
                <div class="row col-pc-6 col-mobile-10">
                  <label for="status-code-type">{{ $t('admin.status-codes.type-of-status') }}</label>
                  <v-select
                    value="PANIC"
                    :options="['AVAILABLE', 'UNAVAILABLE', 'AWAY', 'PANIC']"
                    class="custom-select col-pc-12 col-mobile-12"
                    :clearable="false"
                    disabled
                  ></v-select>
                </div>
              </div>
            </li>
            <li class="default-on-duty-status-code-box col-mobile-12 col-pc-12">
              <div class="li-content col-all-12">
                <div class="name col-pc-9">
                  <div class="text col-pc-12">
                    <h2>{{ $t('admin.status-codes.default-on-duty-status') }}</h2>
                    <i
                      class="fas fa-question-circle tooltip"
                      :uk-tooltip="'title:' + $t('admin.status-codes.default-on-duty-status-help') + ';'"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="panic-status-code-settings-dropdown col-f">
                <div class="row col-pc-12 col-mobile-10">
                  <label for="default-status-code">{{ $t('admin.status-codes.default-on-duty-status') }}</label>
                  <v-select
                    v-model="defaultStatus"
                    appendToBody
                    :calculate-position="withPopper"
                    :reduce="status => status.value"
                    :options="formattedStatuses"
                    :clearable="false"
                    class="custom-select col-pc-12 col-mobile-12 dropdown:box"
                    @input="updateDefaultStatus"
                  ></v-select>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import DropdownPositionCalculator from '@/mixins/DropdownPositionCalculator'

export default {
  name: 'general',
  mixins: [DropdownPositionCalculator],
  data() {
    return {
      statusCode: {
        type: 'available'
      },
      statuses: [],
      defaultStatus: ''
    }
  },
  apollo: {
    statuses: {
      query: require('@/graphql/admin/fetchStatuses.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      fetchPolicy: 'no-cache',
      update: function(data) {
        return data.community.statuses
      },
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToStatuses.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const status = subscriptionData.data.communityUnitStatus

          this.$set(
            this.statuses,
            this.statuses.findIndex(theStatus => theStatus.id === status.node.id),
            status.node
          )
        }
      }
    },
    defaultStatus: {
      query: require('@/graphql/admin/fetchDefaultStatus.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      fetchPolicy: 'no-cache',
      update: function(data) {
        return data.community.defaultStatus.id
      },
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToDefaultStatus.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const status = subscriptionData.data.community

          this.defaultStatus = status.node.defaultStatus.id
        }
      }
    }
  },
  computed: {
    formattedStatuses() {
      return this.statuses
        .filter(status => status.category !== 'PANIC')
        .map(status => {
          return {
            label: status.display,
            value: status.id
          }
        })
    }
  },
  methods: {
    updateStatus(status, combinedInput) {
      if (status.display.length === 0) {
        this.$store.dispatch('errorManager/showError', { error: 'FIELD_CANNOT_BE_EMPTY' })
        if (combinedInput) {
          status.display = combinedInput.oldValue
        }
        return
      }

      const updatedStatus = Object.assign({}, status)
      delete updatedStatus.__typename
      delete updatedStatus.id

      this.$store
        .dispatch('admin/updateStatus', { statusId: status.id, data: updatedStatus })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    updateDefaultStatus() {
      this.$store
        .dispatch('admin/updateDefaultStatus', this.defaultStatus)
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    }
  }
}
</script>
